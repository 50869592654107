import React from "react"

import { Link } from "gatsby"

import LazyAnimationImage from './lazeeAnimationImage'

// import * as headerStyles from './header.module.css'
import "../styles/styles.css"

import { Navbar, Image } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"

import headerImage from "../images/Easter-2025-Header-Footer2.jpg"

const Header = ({ siteTitle }) => {
  return (
    <LazyAnimationImage>
    <Navbar
      sticky="top"
      expand="lg"
      // className="navbar-header"
      style={{ padding: `0` }}
    >
      <Image 
        src={headerImage}
        alt="flowers with green sparkling background"
        fluid 
      />     
        <h1 className="centering-header-title">
          <Link
            to="/"
            className="header-title"
          >  
            {siteTitle} 
          </Link>  
        </h1> 
    </Navbar>
   </LazyAnimationImage> 
  )
}

export default Header

