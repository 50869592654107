import React from "react"

import "../styles/styles.css"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faPhoneSquareAlt } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

import { Navbar, Image, Button } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"

import footerImage from "../images/Easter-2025-Header-Footer2.jpg"

const Footer = () => {

  return(
    // <LazyAnimationImage>
       <Navbar sticky="bottom"
         expand="lg"
        //  className="navbar-footer"
         style={{ padding: `0` }}
        >
           <Image 
             src={footerImage}
             alt="flowers with green sparkling background"
             fluid 
           />  
          <div className="centering-footer-title-and-copyright">
            
                <a href="tel:416-781-5766">
                  <Button 
                    variant="outline-light" 
                    className="footer-buttons" 
                    >
                      <FontAwesomeIcon className="icon-size" icon={faPhoneSquareAlt} />
                          <span className="space-between-icon-and-text">Phone </span>  
                  </Button>   
                </a>
 
                <a href="mailto:vicenzas-crostoli@rogers.com">
                  <Button 
                    variant="outline-light" 
                    className="footer-buttons"
                    >
                      <FontAwesomeIcon className="icon-size" icon={faEnvelope} />
                        <span className="space-between-icon-and-text">Email</span> 
                  </Button>   
                </a>

            <p className="copyright">© {new Date().getFullYear()} Vicenza's Crostoli
            </p>  
           </div>
       </Navbar>
    // </LazyAnimationImage>
  )
}

export default Footer