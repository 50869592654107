import React from 'react'

import * as lazyAnimationImageStyles from './lazeeAnimationImage.module.css'

const LazyAnimationImage = (props) => {
  return (
    <div className={lazyAnimationImageStyles.imageAnimation}>
        {props.children}
    </div>
  );
};

export default LazyAnimationImage;