import React from "react"

import Header from "./header"
import Footer from "./footer"

  function Layout({children}) {
    return (
        <wrapper
          style={{
            display: `flex`,
            flexDirection: `column`,
          }}
        >
          <Header siteTitle="Vicenza's Crostoli" />
            <main
              style={{ flex: `1`, textAlign: `center` }} 
              >
              {children}
            </main>         
          <Footer />
        </wrapper>  
      )
  }

export default Layout